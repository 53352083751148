import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, DoCheck } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { EventService, TAEvent, BingoPackage, EventType, DiscountCoupon, Progressive, FutureEvent, SealTicket, TicketPurchase } from 'src/app/service/event.service';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { promise } from 'protractor';
import { stat } from 'fs';
import * as moment from "moment";
import { CartSesrvice, PaymentInfo, PlayerInfo } from '../service/cart.service';
import { transformAll } from '@angular/compiler/src/render3/r3_ast';
import { toUnicode } from 'punycode';
import { Console } from 'console';
import { data } from 'jquery';
import { Seat, SeatService } from '../service/seating.service';

export interface dropDownVal {
  text: string,
  value: number
}


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
})

export class EventComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = true;
  id: number;
  selectedEvent: TAEvent;
  //eventDateInPast = false;
  bingoPackage: BingoPackage;
  paymentInfo: PaymentInfo;
  errorMessage = '';
  isSubmitting = false;
  showThankyou = false;
  organDonors: Array<string> = [];
  coupons: Array<DiscountCoupon> = [];
  coupon = '';
  couponMessage = '';
  organDonorDiscount = false;
  couponDiscount = false;
  //progressives: Array<Progressive> = [];
  showFreeBingoButton = false;
  showGoToCartButton = false;
  paperStyle: string = 'max-width: 150px;';
  nextFriday: FutureEvent;  
  isLastFriday = false;
  showJackpotAlert = false;
  paperSizes: Array<dropDownVal>;
  extraBookPaperSizes: Array<dropDownVal>;
  paperImage: string = '/assets/img/none.png';
  haveComputers = false;
  generaladminpackages: Array<dropDownVal>;
  generalValueSelected: boolean = false;
  cartBtnText = 'Add to Cart';
  admissionText = 'General Admission rr';
  ticketBase: Array<SealTicket> = [];
  tickets: Array<SealTicket> = [];
  ticketPurchase: Array<TicketPurchase> = [];
  isKidPackage: boolean = false;
  setPrice: number = 0;

  seatoption: string = '';
  showupperseats: string = 'No';
  showlowerseats: string = 'No';
  showgroupname: string = 'No';
  seating: string = '';
  computerSizes: Array<dropDownVal> = [];
  seatingOptions: Array<dropDownVal>;
  selectedseat: Seat;
  selectedEventSeats: Array<string> = [];
  showseatingOptions: boolean = false;

  constructor(protected eventService: EventService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private cartService: CartSesrvice,
    private http: HttpClient,
    private router: Router,
    private ss: SeatService) {
    
  }

  ngAfterViewInit() {
    console.log("After Init");
  }

  ngOnDestroy() {
    console.log("Destry");
  }

  ngDoCheck() {
    console.log("Do check");
  }

  async ngOnInit() { 
    this.route.params.subscribe(params => {
      this.id = params["id"] || "";
    });
    
    this.coupons = [{
      title: 'Free Bingo',
      discountAmount: 1,
      userEmail: '',
      startDate: new Date('2019-01-15T00:00:00'),
      endDate: new Date('2019-03-16T00:00:00'),
    }]

    
    await this.eventService.futureEvents.subscribe(data => {
      
      this.nextFriday = data.find(x => x.title === 'Friday Night Cash Bingo');
      console.log(moment(this.nextFriday.start).toDate().getDate());
      const lastDay = moment(this.nextFriday.start).endOf('month').endOf('day');
      const lastFriday = lastDay.subtract((lastDay.day() + 2) % 7, 'days');
      console.log(lastFriday.toDate().getDate());

      if (moment(this.nextFriday.start).toDate().getDate() === lastFriday.toDate().getDate()) {
        this.isLastFriday = true;
      }
    });
    
    this.eventService.organDonors.subscribe(o => {
      this.organDonors = o;
      if (this.organDonors) {
        console.log('Success');
      }
    });

    this.cartService.message.subscribe(m => this.errorMessage = m);

    this.eventService.getEventById(this.id);
    this.eventService.getEventremianingComputerCount(this.id);
    
   
      this.eventService.selectedEvent.subscribe(e => {
        this.selectedEvent = e;
        console.log(this.selectedEvent);
        
        const now = new Date();
        let evt = new Date(e.startDateTime);

        const type = this.selectedEvent.eventType;

        this.ss.fetchselectedseats(this.selectedEvent.id);

        

        if (type === 6) {
          this.eventService.availableTickets.subscribe(t => {
            this.tickets = t;
            this.ticketBase = t;
          })
        }
        
        this.clearPlayerHandler();
        // { text: "Select a seat upon arrival.", value: 1 },

        this.seatingOptions = [];
        if (this.selectedEvent.showUpperSeating === 'Yes') {
          this.showseatingOptions = true;
          this.seatingOptions = [
            { text: "Select...", value: 0 },
            { text: "Select Upper Level", value: 2 },
            // { text: "Select Lower Level", value: 3 },
            // { text: "Select Group", value: 4 }
          ];
        }

        console.warn(this.seatingOptions)

        if (this.selectedEvent.showUpperSeating === 'Yes' && this.selectedEvent.showLowerSeating === 'Yes') {
          this.showseatingOptions = true;
          this.showupperseats = 'No';
          this.seatingOptions = [
            { text: "Select...", value: 0 },
            { text: "Select Upper Level", value: 2 },
            { text: "Select Lower Level", value: 3 },
            // { text: "Select Group", value: 4 }
          ];
        }



        // this.seatingOptions = [
          
        //   { text: "Select Upper Level", value: 2 },
        //   { text: "Select Lower Level", value: 3 },
        //   // { text: "Select Group", value: 4 }
        // ];

        this.computerSizes = [
          { text: "Select...", value: 0 },
        ];

        if (this.selectedEvent.computerSizeData !== '') {
          let partsOfStr = this.selectedEvent.computerSizeData.split(',');
          partsOfStr.forEach(x => {
            if (x) {
              if (x === '6-Cards') {
                this.computerSizes.push({ text: "6 Cards", value: 6 });
              }

              if (x === '9-Cards') {
                this.computerSizes.push({ text: "9 Cards", value: 9 });
              }

              if (x === '12-Cards') {
                this.computerSizes.push({ text: "12 Cards", value: 12 });
              }

              if (x === '18-Cards') {
                this.computerSizes.push({ text: "18 Cards", value: 18 });
              }

              if (x === '24-Cards') {
                this.computerSizes.push({ text: "24 Cards", value: 24 });
              }

              if (x === '48-Cards') {
                this.computerSizes.push({ text: "48 Cards", value: 48 });
              }

            }

          });
        }
        
        this.paperSizes = [
          { text: "Select (required)", value: 0 }
        ];
        this.bingoPackage.paperSize = 0;

        this.buildPaperSizeDropdown();
        this.extraBookPaperSizes = this.paperSizes;
        
        if (this.selectedEvent.showPrimaryComputer === 'Yes') {
          this.extraBookPaperSizes = [
            { text: "Select...", value: 0 },
            { text: "9-On - (9 Card Book)", value: 9 },
          ];
        }
        
        
        if (this.selectedEvent.eventType === 1) {
          
          if (this.selectedEvent.showDonationBtn !== 'Yes') {

            this.generaladminpackages = [
              { text: "Select...", value: 0 },
              { text: "General Admission", value: this.selectedEvent.eventPrice }
            ];

            if (this.selectedEvent.showVipPayment === 'Yes') {
              this.generaladminpackages.push({ text: "VIP Admission", value: this.selectedEvent.vipPrice });
            }

            if (this.selectedEvent.showKidPayment === 'Yes') {
              this.generaladminpackages.push({ text: "Child Admission", value: this.selectedEvent.kidPrice });
            }

          } else {

            this.generaladminpackages = [
              { text: "Select...", value: 0 },
              { text: "Donation", value: this.selectedEvent.eventPrice }
            ];

            if (this.selectedEvent.showVipPayment === 'Yes') {
              this.generaladminpackages.push({ text: "VIP Donation", value: this.selectedEvent.vipPrice });
            }

            if (this.selectedEvent.showKidPayment === 'Yes') {
              this.generaladminpackages.push({ text: "Child Donation", value: this.selectedEvent.kidPrice });
            }

          }
          

          if (this.selectedEvent.showDonationBtn === 'Yes') {
            this.cartBtnText = "Add Player Donation";
            this.admissionText = 'Donation';
          }

        }
        
        this.paymentInfo = {
          name: '',
          email: '',
          number: undefined,
          exp_month: undefined,
          exp_year: undefined,
          cvc: undefined,
          address_zip: undefined,
          message: '',
          howdidyouhear: ''
        }
        

        this.calculateTotal(); 
        this.loading = false

        // if (this.selectedEvent.id == 430) {
        //   this.coupon = 'Free Bingo';
        //   this.couponchangeHandler();
        // }
    });

    this.eventService.haveComputers.subscribe(c => {
      this.haveComputers = c;
      
      // if (c === false && this.selectedEvent.computer === true) {
      //   this.selectedEvent.computer = false;
      // }
    });

    
    this.ss.selectedeventseats.subscribe(s => {
      this.selectedEventSeats = s;
    });
  }

 



  clearPlayerHandler() {
  
    this.bingoPackage = this.eventService.emptyPlayer();
    this.bingoPackage.packageName = `${this.eventService.getEventType(this.selectedEvent.eventType)} Package`;
    this.bingoPackage.eventId = this.selectedEvent.id;
    this.bingoPackage.eventName = this.selectedEvent.title;
  
    this.setPrice = this.selectedEvent.eventPrice;
    this.ticketPurchase = [];
    this.calculateTotal();
    this.paperImage = '/assets/img/9on.png';
    this.ss.fetchselectedseats(this.selectedEvent.id);
  }

  calculateTotal() {
    // const type = this.selectedEvent.eventType;
    
    // if(type === 0) {
    //   this.calculateEventBingo();
    // } else if(type === 5 ) {
    //   this.calculateHighStakes();
    // }
    // else {
    //   this.calculateCashBingo();
    // }

    console.log("Start Calculate Total")
    const type = this.selectedEvent.eventType;
    let total = 0;

    total = this.bingoPackage.packageQuantity * this.selectedEvent.eventPrice;
    total = total - this.bingoPackage.discount;

    // If type is not General Admission - Calculate Bingo Meta Data
    if (type !== 1) {
      console.log(total);
      total = this.calculatePaperSize(total);
      console.log(total);
      total = this.calculateExtraBook(total);
      console.log(total);
      total = this.calculateComputer(total);
      console.log(total);
      //total = this.calculateSpecials(total);
      console.log(total);
      total = total + this.bingoPackage.hardcard * 5;
      console.log(total);
      total = total + this.bingoPackage.quickdraw * 5;
      console.log(total);
      total = total + this.bingoPackage.deal * 1;
      console.log(total);
      total = total + this.bingoPackage.money * 1;
      console.log(total);
      total = total + this.bingoPackage.plinko * 1;
      console.log(total);
      total = total + this.bingoPackage.speedball * 1;
      console.log(total);

      if (this.bingoPackage.winnersChoice == 'Yes') {
        total = total + 5;
        console.log(total);
      }
      
      

      // Specials
      total = this.calculate_1_3_6_9_12_15('Specials', total, this.bingoPackage.special, 3);
      // SafeCracker
      console.log(this.bingoPackage.safe)
      total = total + this.bingoPackage.safe * 1;
      console.log(total);
      // Bonanaza
      total = this.calculateBonanza(total);
      console.log(total);
      // Pick4
      total = this.calculate_1_for_3_or_2_for_5('Pick4', total, this.bingoPackage.play4);
      console.log(total);
      // Leprechaun
      total = this.calculate_1_for_3_or_2_for_5('Leprechaun', total, this.bingoPackage.leprechaun);
      console.log(total);
      // Butterfly
      total = this.calculate_1_for_3_or_2_for_5('Butterfly', total, this.bingoPackage.butterfly);
      console.log(total);
      // Punch2Win
      total = this.calculate_1_for_3_or_2_for_5('Punch2Win', total, this.bingoPackage.punch2Win);
      console.log(total);
      // WackyWheel
      total = this.calculate_1_for_3_or_2_for_5('WackyWheel', total, this.bingoPackage.wackyWheel);
      console.log(total);
      // Mini-Jackpot
      total = this.calculate_1_for_3_or_2_for_5('Mini-Jackpot', total, this.bingoPackage.miniJackpot);
      console.log(total);
      // Jackpot
      total = this.calculateJackpot(total);
      // Wizard of Oz
      total = this.calculate_1_3_6_9_12_15('WizardofOz', total, this.bingoPackage.wizardofoz, 2);

    }

    console.log(this.bingoPackage);
    console.log(total);
    this.bingoPackage.total = total;
    console.log("End Calculate Total")
    
  }

  calculatePaperSize(total: number) {
    console.log(`Calculate Papersize: ${this.bingoPackage.paperSize}`);
    if (this.selectedEvent.showPaperSize === 'Yes') {
      if (this.bingoPackage.paperSize == 18) {
        console.log("Calculcate 18on");
        total = total + 10;
      } else if (this.bingoPackage.paperSize == 12) {
        console.log("Calculcate 12on");
        total = total + 5;
      }
    }
    return total;
  }

  calculate_1_3_6_9_12_15(item: string, total: number, quanity: number, amt: number) {
    console.log('Calculate ' + item + ' quanity: ' + quanity.toString());
    if (quanity > 0) {
      if (quanity === 1) {
        total = total + amt;
      } else if (quanity === 3) {
        total = total + 5;
      } else if (quanity === 6) {
        total = total + 10;
      } else if (quanity === 9) {
        total = total + 15;
      } else if (quanity === 12) {
        total = total + 20;
      } else if (quanity === 15) {
        total = total + 25;
      }
    }
    return total;
  }

  calculateEventBingo() {
    console.log('Calculate Event Bingo');
    let total = 0;
    total = this.bingoPackage.packageQuantity * this.selectedEvent.eventPrice;
    total = this.calculateExtraBook(total);
    total = this.calculateComputer(total);
    
    if (this.bingoPackage.winnersChoice === 'Yes') {
      total = total + 5;
    }

    if (this.bingoPackage.special === 1) {
      total = total + 3;
    } else if (this.bingoPackage.special === 3) {
      total = total + 5;
    } else if (this.bingoPackage.special === 6) {
      total = total + 10;
    } else if (this.bingoPackage.special === 9) {
      total = total + 15;
    } else if (this.bingoPackage.special === 12) {
      total = total + 20;
    } else if (this.bingoPackage.special === 15) {
      total = total + 25;
    }

    const p = this.bingoPackage.jackpot * 2.50;
    total = total + Math.ceil(p);
    total = total - this.bingoPackage.discount;
    this.bingoPackage.total = total;


  }

  calculateExtraBook(total: number) {
    console.log('Calculate Extra Book');
    if (this.bingoPackage.extraBook > 0) {
      total = total + 10;
    }
    return total;
  }

  calculateComputer(total: number) {

    let sizes: Array<number> = [];

    let partsOfStr = this.selectedEvent.computerSizeData.split(',');
    partsOfStr.forEach(x => {
      if (x) {
        if (x === '6-Cards') {
          sizes.push(6);
        }

        if (x === '9-Cards') {
          sizes.push(9);
        }

        if (x === '12-Cards') {
          sizes.push(12);
        }

        if (x === '18-Cards') {
          sizes.push(18);
        }

        if (x === '24-Cards') {
          sizes.push(24);
        }

        if (x === '36-Cards') {
          sizes.push(24);
        }

        if (x === '48-Cards') {
          sizes.push(48);
        }

      }

    });

    if (this.selectedEvent.showPrimaryComputer === 'Yes') {
      const smallest = sizes.reduce((a, b) => Math.min(a, b));
      if (this.bingoPackage.computerSize > smallest) {
        total = total + 20;
      }
      this.bingoPackage.paperSize = 9;
    }


    if (this.selectedEvent.showComputerAsExtra === 'Yes') {
      console.log("Computer-Extra");
      if (this.bingoPackage.computerSize > 0) {
        total = total + 10;
      }
    }

    return total;
  }

  
  calculateBonanza(total: number) {
    console.log("Calculate Bonanza");
    if (this.bingoPackage.ticTac > 0) {
      if (this.bingoPackage.ticTac < 5) {
        total = total + this.bingoPackage.ticTac;
      }
      else {
        const t = this.bingoPackage.ticTac * .82;
        console.log(t);
        total = total + Math.ceil(t);
      }
    }

    return total
  }



  calculate_1_for_3_or_2_for_5(item: string, total: number, quanity: number) {
    console.log('Calculate ' + item + ' quanity: ' + quanity.toString());
    if (quanity === 1) {
      total = total + 3;
    } else if (quanity > 1) {
      const p = quanity * 2.50;
      total = total + Math.ceil(p);
    }
    return total;
  }

  calculateJackpot(total: number) {
    console.log('Calculate Jackpot');
    if (this.bingoPackage.jackpot === 1) {
      total = total + 3;
    } else if (this.bingoPackage.jackpot > 1) {
      const p = this.bingoPackage.jackpot * 2.50;
      total = total + Math.ceil(p);
    }
    return total;
  }

  
  computerSizeChangeHandler(e: any) {
    console.log(this.bingoPackage.computerSize);
    this.bingoPackage.computerSize = Number(this.bingoPackage.computerSize);
    if (this.bingoPackage.computerSize > 0) {
      this.bingoPackage.computer = true;      
    } 
    else {
      this.bingoPackage.computer = false;
    }
    this.calculateTotal();
  }

  quantityChangeHandler(e) {    
    this.bingoPackage.packageQuantity = Number(e.target.value);   
    this.calculateTotal(); 
  }

  generalChangeHandler(e) {
    console.log('Calculate General');
    console.log(e.target.value);
    let total = 0;
    this.generalValueSelected = false;
    
    if (e.target.value !== 0) {
      console.log('Calculate General Value');
      this.bingoPackage.packageName = `${e.target.text} Package`;
      total = this.bingoPackage.packageQuantity * e.target.value;
      total = total - this.bingoPackage.discount;
      this.bingoPackage.total = total;

      if (e.target.value < this.selectedEvent.eventPrice) {
        this.bingoPackage.packageName = "Kids Package";
      } else if (e.target.value > this.selectedEvent.eventPrice) {
        this.bingoPackage.packageName = "VIP Package";
      } else {
        this.bingoPackage.packageName = "General Admission";
      }

      this.generalValueSelected = true;
    }      
  }

  paperSizeChangeHandler(e) {
    console.log(e);
    this.extraBookPaperSizes = this.paperSizes;
    if (e.target.value == 6) {
      this.paperImage = '/assets/img/6on.png';
      this.extraBookPaperSizes = [
        { text: "Select...", value: 0 },
        { text: "6-On - (6 Card Book)", value: 6 }
      ];
    } else if (e.target.value == 9) {
      this.paperImage = '/assets/img/9on.png';
      this.extraBookPaperSizes = [
        { text: "Select...", value: 0 },
        { text: "9-On - (9 Card Book)", value: 9 }
      ];
    } else if (e.target.value == 12) {      
      this.paperImage = '/assets/img/12on.png';
      this.extraBookPaperSizes = [
        { text: "Select...", value: 0 },
        { text: "9-On - (9 Card Book)", value: 9 },
        { text: "12-On - (12 Card Book)", value: 12 },
      ];
    } else if (e.target.value == 18) {
      this.paperImage = '/assets/img/18on.png';
      this.extraBookPaperSizes = [
        { text: "Select...", value: 0 },
        { text: "9-On - (9 Card Book)", value: 9 },
        { text: "12-On - (12 Card Book)", value: 12 },
        { text: "18-On - (18 Card Book)", value: 18 }
      ];
    }
    console.log(this.bingoPackage.paperSize);
    this.calculateTotal();
  }

  extraBookHandler(e) {
    this.bingoPackage.extraBook = Number(e.target.value);   
    this.calculateTotal(); 
  }

  quickDrawHandler(e) {
    this.bingoPackage.quickdraw = Number(e.target.value);   
    this.calculateTotal(); 
  }

  hardCardHandler(e) {
    this.bingoPackage.hardcard = Number(e.target.value);   
    this.calculateTotal(); 
  }

  dealHandler(e) {
    this.bingoPackage.deal = Number(e.target.value);   
    this.calculateTotal(); 
  }

  speedballHandler(e) {
    this.bingoPackage.speedball = Number(e.target.value);
    this.calculateTotal();
  }

  moneyHandler(e) {
    this.bingoPackage.money = Number(e.target.value);   
    this.calculateTotal(); 
  }

  plinkoHandler(e) {
    this.bingoPackage.plinko = Number(e.target.value);   
    this.calculateTotal(); 
  }

  specialsHandler(e) {
    this.bingoPackage.special = Number(e.target.value);   
    this.calculateTotal(); 
  }
  

  winnerschoiceHandler(e) {
    console.log(e.target.value)
    this.bingoPackage.winnersChoice = e.target.value;
    console.log(this.bingoPackage);
    this.calculateTotal();
  }

  jackpotHandler(e) {
    this.showJackpotAlert = true;
    this.bingoPackage.jackpot = Number(e.target.value);   
    this.calculateTotal(); 
  }

  safeHandler(e) {
    this.bingoPackage.safe = Number(e.target.value);   
    this.calculateTotal(); 
  }

  punchHandler(e) {
    this.bingoPackage.punch2Win = Number(e.target.value);
    this.calculateTotal();
  }

  play4Handler(e) {
    this.bingoPackage.play4 = Number(e.target.value);
    this.calculateTotal();
  }

  leprechaunHandler(e) {
    this.bingoPackage.leprechaun = Number(e.target.value);
    this.calculateTotal();
  }

  butterflyHandler(e) {
    this.bingoPackage.butterfly = Number(e.target.value);
    this.calculateTotal();
  }

  miniJackpotHandler(e) {
    this.bingoPackage.miniJackpot = Number(e.target.value);
    this.calculateTotal();
  }

  wackyWheelHandler(e) {
    this.bingoPackage.wackyWheel = Number(e.target.value);
    this.calculateTotal();
  }
 
  // BONANZA
  tictacHandler(e) {
    this.bingoPackage.ticTac = Number(e.target.value);
    this.calculateTotal();
  }

  wizardofOzHandler(e: any) {
    this.bingoPackage.wizardofoz = Number(e.target.value);
    this.calculateTotal();
  }

  emailchangeHandler() {
    console.log(`Organ Donor Test for:  ${this.bingoPackage.email}`);
    
    const donorTest = this.organDonors.find(x => this.bingoPackage.email.toLowerCase() === x.toLowerCase());
    this.bingoPackage.discount = 0;
    if (donorTest) {  
      if (this.selectedEvent.showDiscount === 'Yes') {

        let cart: Array<PlayerInfo> = [];
        this.cartService.players.subscribe(x => cart = x);
        
        var hasSameUserAndSession = cart.find(x => x.info.eventId === this.bingoPackage.eventId
          && x.info.email === this.bingoPackage.email)

        if (hasSameUserAndSession) {
          this.couponMessage = 'Organ Donor discount has already been applied to this event and email';
          this.bingoPackage.discount = 0;
          this.calculateTotal();
          this.organDonorDiscount = false;
        } else {
          this.bingoPackage.discount = 5;
          if (!this.organDonorDiscount)
            this.bingoPackage.total = this.bingoPackage.total - 5;

          this.organDonorDiscount = true;
        }        
      } 
      else 
      {
        this.couponMessage = 'Organ Donor Discount not available on this event.';
        }
      
    } else {
      this.bingoPackage.discount = 0;
      this.calculateTotal();
      this.organDonorDiscount = false;
    }
  }


  enableDisableCartBtn() {
    let retVal = false;
    // isSubmitting || firstname.invalid || lastname.invalid || bingoPackage.paperSize < 6
    if (this.isSubmitting === false) {
      retVal = true;
    }

  }

  addToCartHandler() {
    console.log('Package:', this.bingoPackage);
    let okToContinue = 'yes';
    if (this.bingoPackage.seatoption === '' && this.showseatingOptions === true) {
      alert('Please select a seating preference');
      okToContinue = 'seating';
    }
    

    if (this.selectedEvent.showDonationBtn === 'Yes') {
      this.bingoPackage.packageName = this.bingoPackage.packageName + ' Donation';
    }
        
    let cart: Array<PlayerInfo> = [];
    this.cartService.players.subscribe(x => cart = x);
    console.log(cart);
    

    cart.forEach(c => {
      if (c.info.eventName === this.bingoPackage.eventName
        && c.info.firstName === this.bingoPackage.firstName
        && c.info.lastName === this.bingoPackage.lastName) {
          okToContinue = 'player name';
        }
    });
    
    if (okToContinue === 'seating') {
      alert(`Please select a seating option`);
    }
    else if (okToContinue === 'player name') {
      alert(`Please fix the name of this attendee. You have already added ${this.bingoPackage.firstName}. We need to know each person individually at each event. Thank you!`);
    }
    else {

      let id = cart.length + 1;

      if (this.selectedEvent.eventType === 6) {
        this.bingoPackage.tickets = this.ticketPurchase;
      }
      else {
        this.bingoPackage.tickets = [];
      }
      
      console.log(cart);
      cart.push({ id: id, info: this.bingoPackage, vendor: null });
      console.log(cart);
      this.cartService.players.next(cart);
      localStorage.setItem('cart', JSON.stringify(cart));

      this.cartService.players.subscribe(x => {
        console.log(x);
        console.log(x.length);
        if (x.length > 0) {
          this.showGoToCartButton = true;
          this.clearPlayerHandler();
        }
      });

    }
  }

  

  kidCheckboxHandler(event: any) {
    console.log(event);
    if (event === 'yes') {
      this.selectedEvent.eventPrice = this.selectedEvent.kidPrice;
      this.isKidPackage = true;
      if (this.isKidPackage) {
        this.paperSizes = [
          { text: "4-On - (4 Card Book)", value: 4 }
        ];
        this.paperImage = '/assets/img/4on.png';
        
      }
    } else {
      this.selectedEvent.eventPrice = this.setPrice;
      this.isKidPackage = false;
      this.buildPaperSizeDropdown();
      this.extraBookPaperSizes = this.paperSizes;
      this.paperImage = '/assets/img/none.png';
    }
    this.calculateTotal();
  }

  addAnotherPlayerHandler() {
    this.showGoToCartButton = false;    
    this.generalValueSelected = false;
  }

  ticketCounterHandler(evt: number, t: SealTicket) {

    const p = {
      name: t.name,
      quantity: evt,
    }
    
    const idx = this.ticketPurchase.findIndex(x => x.name == p.name);
    if (idx > -1) {
      this.ticketPurchase.splice(idx, 1);
    }
      
    this.ticketPurchase.push(p);
    const count = this.tickets.find(x => x.name == t.name).ticketCount;
    this.tickets.find(x => x.name == t.name).ticketCount = count - evt;
    console.log(this.ticketPurchase);
    this.calculateTotal();
  }

  seatingChangeHandler(sel: number) {
    console.log(sel);
    this.seating = '';
    this.seatoption = '';
    this.showupperseats = 'No';
    this.showlowerseats = 'No';
    this.showgroupname = 'No';

    if (sel == 1) {
      this.seatoption = 'Arrival';
      this.seating = 'none';
    }
    else if (sel == 2) {
      this.showupperseats = 'Yes';
      this.seating = 'upper';
    }
    else if (sel == 3) {
      this.showlowerseats = 'Yes';
      this.seating = 'lower';
    }
    else if (sel == 4) {
      this.showgroupname = 'Yes';
      this.seating = 'group';
    }

    console.log('option: ' + this.seatoption);
    console.log('upper: ' + this.showupperseats);
    console.log('lower: ' + this.showlowerseats);
    console.log('group: ' + this.showgroupname);
    
  }  

  selectedseatHandler(s: Seat) {
    console.log(s);
    console.log(this.selectedseat);
    console.log(this.selectedEvent);
    

    if (s.occupied === 'Yes') {
      if (this.seatoption === '') {
        console.log(`Set Seat ${s.level}-${s.row}:${s.seatNo}`)
        this.seatoption = `${s.level}-${s.row}:${s.seatNo}`
        this.ss.selectedseat.emit(s);
        this.bingoPackage.seatoption = this.seatoption;
      } else {
        alert('You can only select one seat per player.');
      }
      
    } else {
      console.log('Clear Seat')
      this.seatoption = '';
      this.bingoPackage.seatoption = '';
    }
    

  }

  buildPaperSizeDropdown() {
    if (this.selectedEvent.eventType !== 1) {
      let psizes = ['9','12','18'];
      try {
        psizes = this.selectedEvent.paperSizeData.split(",");
      }
      catch
      {
        psizes = ['9', '12', '18'];
      }
      
      this.paperSizes = [
        { text: "Select (required)", value: 0 }
      ];

      this.bingoPackage.paperSize = 0;
      this.paperImage = '/assets/img/none.png';

      psizes.forEach((p: string) => {
        if (p === '4') {
          this.paperSizes.push({ text: "4-On - (4 Card Book)", value: 4 },)
        }
        else if (p === '6') {
          this.paperSizes.push({ text: "6-On - (6 Card Book)", value: 6 },)
        }
        if (p === '9') {
          this.paperSizes.push({ text: "9-On - (9 Card Book)", value: 9 },)
        }
        if (p === '12') {
          this.paperSizes.push({ text: "12-On - (12 Card Book)", value: 12 },)
        }
        if (p === '18') {
          this.paperSizes.push({ text: "18-On - (18 Card Book)", value: 18 },)
        }
      });  
    }
    
  }

}
  

  
